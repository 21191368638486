import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { browserTracingIntegration, init, replayIntegration } from '@sentry/remix';
import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';

init({
  enabled: import.meta.env.PROD,
  dsn: 'https://1bef992974d8fa34ea004c859e09f83c@o4506942734532608.ingest.us.sentry.io/4506942738006016',
  integrations: [
    browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
      enableInp: true
    }),
    replayIntegration()
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser/>
    </StrictMode>
  );
});
